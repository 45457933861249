<template>
  <div class="flex flex-col w-full h-full p-5">
    <div class="page-title">
      <h2>
        {{ content.title }}
      </h2>
    </div>
    <div>
      <p class="text-xl leading-snug mb-10">
        {{ content.paragraph1 }}
      </p>
      <!-- use v-html to load in text with hyperlink GUIDE -->
      <p
        class="text-xl leading-snug mb-10"
        v-html="content.paragraph2"
      />
      <p class="text-xl leading-snug mb-10">
        {{ content.paragraph3 }}
      </p>
    </div>
  </div>
</template>

<script>

import { About } from '@/content/reportContent.json'

export default {
  data () {
    return {
      content: About
    }
  }
}
</script>

<style>

</style>
