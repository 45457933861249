<template>
  <div class="flex flex-col w-full h-full p-5">
    <div class="page-title">
      <h2>
        {{ content.title }}
      </h2>
      <p class="mb-10">
        {{ content.description }}
        <br>
      </p>
    </div>
    <div
      class="flex items-center h-full mt-4 justify-evenly"
    >
      <div class="flex items-center">
        <img
          src="@/assets/chartExamples/bell_curve_stanine.png"
          alt=""
          width="700"
        >
      </div>
      <div class="flex items-center w-1/3 h-full">
        <table class="table w-2/3 border-2 table-compact h-2/3">
          <thead>
            <tr>
              <th class="text-sm border-b dark:border-slate-600 font-medium p-8 text-slate-400 dark:text-slate-200 text-left">
                Stanine score
              </th>
              <th class="text-sm border-b dark:border-slate-600 font-medium p-8 text-slate-400 dark:text-slate-200 text-left">
                % in the group with this result
              </th>
            </tr>
          </thead>

          <tbody class="text-center">
            <tr>
              <td>
                <span class="text-xl">1</span>
              </td>
              <td>
                <span class="text-xl">4 %</span>
              </td>
            </tr>
            <tr>
              <td> <span class="text-xl">2</span></td>
              <td> <span class="text-xl">7 %</span></td>
            </tr>
            <tr>
              <td> <span class="text-xl">3</span></td>
              <td> <span class="text-xl">12 %</span></td>
            </tr>
            <tr>
              <td> <span class="text-xl">4</span></td>
              <td> <span class="text-xl">17 %</span></td>
            </tr>
            <tr>
              <td> <span class="text-xl">5</span></td>
              <td> <span class="text-xl">20 %</span></td>
            </tr>
            <tr>
              <td> <span class="text-xl">6</span></td>
              <td> <span class="text-xl">17 %</span></td>
            </tr>
            <tr>
              <td> <span class="text-xl">7</span></td>
              <td> <span class="text-xl">12 %</span></td>
            </tr>
            <tr>
              <td> <span class="text-xl">8</span></td>
              <td> <span class="text-xl">7 %</span></td>
            </tr>
            <tr>
              <td> <span class="text-xl">9</span></td>
              <td> <span class="text-xl">4 %</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import { Scales } from '@/content/reportContent.json'

export default {
  data () {
    return {
      content: Scales
    }
  }
}
</script>

<style>
</style>
