<template>
  <div class="flex flex-col w-full h-full p-5 candidate-match">
    <!-- <chart-tooltip
      ref="tooltip"
      type="spider"
      :has-play="true"
    /> -->
    <div class="page-title">
      <h2>
        <span v-if="pdf || fromOtp">
          {{ content.pdfTitle }}

        </span>
        <span v-else>
          {{ content.title }}

        </span>
        <p class="text-xl">
          <question-icon
            class="float-right"
            style="display: none;"
            :questions="content.questions"
          />
        </p>
      </h2>
      <p v-if="pdf || fromOtp">
        {{ content.pdfDescription }}
      </p>
      <p v-else>
        {{ content.description }}
      </p>
    </div>
    <div class="flex items-center">
      <!-- Search box and compare candidate -->
      <!-- not shown if pdf or otp -->
      <div
        class="search-box"
        v-if="!pdf && !fromOtp"
      >
        <div class="w-full pr-2 mb-3">
          <select
            class="w-full select select-bordered text-base h-20"
            v-model="selectedRequirementSpecification"
            @change="createChartDatasets()"
          >
            <option :value="null">
              No requirement specification
            </option>
            <option disabled>
              ---
            </option>
            <option
              v-for="requirementSpecification in requirementSpecifications"
              :value="requirementSpecification"
              :key="requirementSpecification.id"
            >
              {{ requirementSpecification.name }}
            </option>
          </select>
        </div>
        <div class="flex">
          Show candidate information
          <show-details-btn
            class="ml-2"
            v-model="showCanidateInformation"
          />
        </div>
        <input
          type="text"
          v-model="search"
          placeholder="Search candidates..."
          class="p-2 border-2 rounded-lg search-input"
        >
        <ul class="flex flex-col overflow-y-auto h-96">
          <li
            v-for="candidate in candidates.filter(filterCandidates)"
            :key="candidate.user_id"
            class="mb-4"
          >
            <div class="flex flex-row items-center w-full">
              <div class="flex flex-row items-center w-full">
                <input
                  v-model="selectedCandidates"
                  :value="candidate.user_id"
                  type="checkbox"
                  class="mr-4 checkbox w-8 h-8"
                >
                {{ (candidate.name && showCanidateInformation) ? candidate.name : `Candidate ${candidate.alt_id}` }}
              </div>
              <div
                v-if="selectedRequirementSpecification && candidateMatchingScores[candidate.user_id] >= 0"
                class="flex flex-row w-1/2 items-center"
              >
                <MatchingBox :matching="candidateMatchingScores[candidate.user_id]" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div>
        {{ results }}
      </div> -->
      <!-- <div>
        {{ data }}
      </div> -->
      <!-- Spider chart -->
      <div class="flex-auto w-64 mx-8 spider-box">
        <div class="w-52" />
        <div class="flex flex-col items-center">
          <!-- <div
            class="summary-box-profile-match"
            v-if="selectedMainCandidate"
          >
            <p class="text-sm font-bold">
              Total capacity score: {{ parseFloat(mainCandidate.total_capacity_score).toFixed(1) }}
            </p>
          </div> -->
          <radar-chart
            v-if="loadedTooltip"
            key="comparisonSpider"
            ref="chart"
            :width="725"
            :height="725"
            :chart-data="data"
            :options="options"
          />
          <!-- // :plugins="[$refs.tooltip.plugin]" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TeamAbilityComparisonRadar, Charts } from '@/content/reportContent.json'
import { RadarChart } from 'vue-chart-3'

// import ChartTooltip from './ChartTooltip'
import QuestionIcon from './QuestionIcon.vue'
import ShowDetailsBtn from '@/modules/Scoreboard/components/ShowDetailsBtn.vue'
import MatchingBox from '@/components/requirement/MatchingBox.vue'

import { candidateStyling, normStyling } from '@/utils/chartUtils.js'

import { mapGetters, mapState } from 'vuex'

// UNIQUE COLOR GENERATOR
function getUniqueColor (n) {
  const rgb = [0, 0, 0]

  for (let i = 0; i < 24; i++) {
    rgb[i % 3] <<= 1
    rgb[i % 3] |= n & 0x01
    n >>= 1
  }

  return '#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '')
}

const stanineMap = {
  '-10': '',
  '-1': 1,
  7: 2,
  16: 3,
  31: 4,
  49.5: 5,
  68: 6,
  82.5: 7,
  92: 8,
  100: 9
}
const stanineMapReversed = {
  0: -30,
  1: -1,
  2: 7,
  3: 16,
  4: 31,
  5: 49.5,
  6: 68,
  7: 82.5,
  8: 92,
  9: 100
}

export default {
  components: { RadarChart, QuestionIcon, ShowDetailsBtn, MatchingBox },
  props: {
    pdf: Boolean,
    chartData: {
      type: Array,
      required: true
    },
    fromOtp: {
      type: Boolean,
      default: false,
      required: false
    },
    campaignId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      content: TeamAbilityComparisonRadar,
      loadedTooltip: false,
      search: '',
      selectedRequirementSpecification: null,
      showCanidateInformation: false,
      selectedCandidates: [],
      candidateMatchingScores: {},
      colors: ['#e11845', '#87e911', '#0057e9', '#ff00bd', '#f2ca19', '#8931ef', 'rgb(240,163,255)', 'rgb(0,117,220)',
        'rgb(153,63,0)', 'rgb(76,0,92)', 'rgb(25,25,25)', 'rgb(0,92,49)', 'rgb(43,206,72)',
        'rgb(255,204,153)', 'rgb(128,128,128)', 'rgb(148,255,181)', 'rgb(143,124,0)', 'rgb(157,204,0)',
        'rgb(194,0,136)', 'rgb(0,51,128)', 'rgb(255,164,5)', 'rgb(255,168,187)', 'rgb(66,102,0)'],
      data: {
        labels: this.chartData.map(el => el.label),
        datasets: null
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        animation: (this.pdf) ? {
          duration: 0
        } : undefined,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              font: {
                size: 16
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (ctx) => {
                if (stanineMap[ctx.parsed.r]) {
                  return `${ctx.dataset.label}: ${stanineMap[ctx.parsed.r]}`
                }
                return `${ctx.dataset.label}: 0`
              }
            }
          }
        },
        scales: {
          r: {
            grid: {
              lineWidth: 2
            },
            max: 100,
            min: -30,
            ticks: {
              showLabelBackdrop: false,
              padding: 0,
              stepSize: 0.5,
              z: 1,
              callback (tickValue) {
                return stanineMap[tickValue.toString()]
              },
              font: {
                size: 10
              }
            },
            pointLabels: {
              font: {
                size: 16,
                weight: 'bold'
              },
              color: label => {
                // color the labels depening on score. 4> green 3-4 yellow 2 < red
                if (this.data.datasets[0] && this.data.datasets.length === 2) {
                  const value = stanineMap[this.data.datasets[0].data[label.index].toString()]

                  if (value === 0) {
                    return 'black'
                  } if (value > 4) {
                    return 'green'
                  } else if (value === 3 || value === 4) {
                    return '#f1ca19' // yellow
                  } else if (value < 3) {
                    return 'red'
                  } else {
                    return 'black'
                  }
                } else {
                  return 'black'
                }
              },
              callback: label => {
                if (label.includes('&')) {
                  const split = label.split('&')
                  split[0] = split[0] + ' &'
                  return split
                }
                return label
              }
            }
          }
        }
      }
    }
  },
  async created () {
    await this.$store.dispatch('TEAMREPORT/getResultsComparison', this.campaignId)

    // disable if otp
    if (!this.fromOtp) {
      await this.$store.dispatch('REQUIREMENT_SPECIFICATIONS/getRequirementSpecifications')
    }

    const nFavorites = this.candidates.filter((candidate) => candidate.favorite).length

    // Fetch and load results for all favorited candidates (if none are favorited then show all)
    for (const candidate of this.candidates) {
      if (candidate.favorite || nFavorites === 0) {
        this.selectedCandidates.push(candidate.user_id)
      }
    }

    // if there is more candidates than colors then generate new
    if (this.colors.length < this.candidates.length) {
      this.candidates.forEach((_, index) => this.colors.push(getUniqueColor(index)))
    }

    // If we are signed in as ADMIN
    if (this.isLoggedAdminIn) {
      this.colors.push(getUniqueColor(200))
    }
  },
  mounted () {
  },
  watch: {
    showCanidateInformation () {
      this.createChartDatasets()
    },
    async selectedCandidates () {
      await this.$store.dispatch('TEAMREPORT/getResultsComparison', this.campaignId)
      this.createChartDatasets()
    }
  },
  computed: {
    ...mapState('TEAMREPORT', ['candidates', 'results']),
    ...mapGetters({
      requirementSpecifications: 'REQUIREMENT_SPECIFICATIONS/getRequirementSpecifications',
      isLoggedAdminIn: 'ADMIN/isLoggedAdminIn'
    })
  },
  methods: {
    createChartDatasets () {
      this.candidateMatchingScores = {}

      // parse requirements
      const reqMainMeasures = []
      const mainMeasuresIOAverages = [] // averages of importance and occurence
      if (this.selectedRequirementSpecification) {
        this.selectedRequirementSpecification.Requirements.forEach(result => {
          const mainMeasure = Charts.MainMeasures[result.measure]
          if (mainMeasure) {
            reqMainMeasures[mainMeasure.order] = result.stanine
            mainMeasuresIOAverages[mainMeasure.order] = Math.floor(1 / 2 * (result.importance + result.occurrence)) // to replicate what's happening in the backend
          }
        })

        for (const key in Charts.MainMeasures) {
          const index = Charts.MainMeasures[key].order
          if (!reqMainMeasures[index]) {
            reqMainMeasures[index] = 0
          }
        }
      }

      const processedUserIds = []

      this.data.datasets = this.results
        .filter(candidate => this.selectedCandidates.includes(candidate.user_id))
        .map(candidate => {
          const candidateId = candidate.user_id
          const candidateIndex = this.candidates.findIndex(el => el.user_id === candidateId)
          const color = this.colors[candidateIndex] || this.colors[this.colors.length - 1]

          // Check if the candidateId is already processed. If so, return null to filter it out later.
          if (processedUserIds.includes(candidateId)) {
            return null
          }

          // Mark the candidateId as processed in the array
          processedUserIds.push(candidateId)

          const mainMeasures = []
          let matchSum = 0

          this.results.filter(result => result.user_id === candidateId).forEach(result => {
            const mainMeasure = Charts.MainMeasures[result.measure]

            if (mainMeasure) {
              mainMeasures[mainMeasure.order] = result.stanine
              if (this.selectedRequirementSpecification) {
                matchSum += Math.min(1, result.stanine / mainMeasuresIOAverages[mainMeasure.order])
              }
            }
          })

          // formula: capacity / ((importance + occurrence) /2)
          // capacity scores > (importance + occurrence) /2 get set to (importance + occurrence) /2
          this.candidateMatchingScores[candidateId] = matchSum / reqMainMeasures.length

          for (const key in Charts.MainMeasures) {
            const index = Charts.MainMeasures[key].order
            if (!mainMeasures[index]) {
              mainMeasures[index] = 0
            }
          }

          const label = (this.candidates[candidateIndex].name && this.showCanidateInformation) ? this.candidates[candidateIndex].name : `Candidate ${this.candidates[candidateIndex].alt_id}`

          return {
            label,
            data: mainMeasures.map(val => stanineMapReversed[val]),
            fill: false,
            backgroundColor: color,
            borderColor: color,
            pointBackgroundColor: color,
            pointBorderColor: color,
            pointHoverBackgroundColor: color,
            pointHoverBorderColor: color
          }
        }).filter(dataset => dataset !== null)

      if (this.selectedRequirementSpecification) {
        this.data.datasets.push({
          label: 'Requirement specification',
          data: reqMainMeasures.map(val => stanineMapReversed[val]),
          ...candidateStyling
        })
      }

      // the norm
      this.data.datasets.push({
        label: 'The Norm',
        data: Array(Object.keys(Charts.MainMeasures).length).fill(5).map(val => stanineMapReversed[val]),
        ...normStyling
      })
      this.loadedTooltip = true
    },
    filterCandidates (candidate) {
      const name = (candidate.name && this.showCanidateInformation) ? candidate.name : `Candidate ${candidate.alt_id}`

      // Check if the candidate's user_id exists in this.results
      const hasResult = this.results.some(result => result.user_id === candidate.user_id)

      return name.toLowerCase().includes(this.search.toLowerCase()) && hasResult
    }
  }

}
</script>
