<template>
  <div
    class="w-full h-full"
    :class="{ 'flex flex-col ': !pdf }"
  >
    <div
      v-if="isDownloading"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-80"
    >
      <button class=" btn btn-lg btn-circle btn-ghost loading" />
    </div>
    <div
      v-if="expired"
      class="flex items-center justify-center flex-auto"
    >
      <h5>
        Expired
      </h5>
    </div>
    <div
      v-else
      class="w-full h-full"
      :class="{ 'flex flex-col ': !pdf }"
      @click="closeReportModals = !closeReportModals"
    >
      <horizontal-report-menu
        v-if="!expired"
        :is-team="true"

        :report-result-pages="reportResultPages"
        :report-info-pages="reportInfoPages"
        v-model="page"

        @download="downloadReport"
        @downloadRawDataReport="downloadRawDataReport"
        @share="showShareModal = true"

        :campaign-id="campaignId"
        :from-otp="!!otp"
        :allow-raw-data-report="!!isLoggedAdminIn"

        :close-report-modals="closeReportModals"
        :loaded-data="loadedData"
      />
      <!-- <welcome-page
        :show-no-results-msg="false"
        :pdf="pdf"
        v-show="page == 0 || pdf"
      /> -->
      <about-page v-show="page === 0 || pdf" />
      <about-the-norm-page v-show="page === 1 || pdf" />
      <scales-page v-show="page === 2 || pdf" />
      <game-analysis-page v-show="page === 3 || pdf" />

      <comparison-page
        v-if="page === 4 || (pdf && loadedData)"
        :chart-data="mainMeasures"
        :pdf="pdf"
        :from-otp="!!otp"
        :campaign-id="campaignId"
      />
    </div>

    <!-- Modal -->
    <input
      type="checkbox"
      id="my-modal"
      class="hidden modal-toggle"
      :checked="showCheckoutInfo"
    >
    <div class="modal">
      <div class="modal-box">
        <h3 class="text-lg font-bold">
          Free trial has expired.
        </h3>
        <p class="py-4">
          To continue, please fill in you payment details.
        </p>
        <div class="modal-action">
          <router-link
            :to="{ name: 'TeamScoreboard', params: { campaignId: this.$route.params.campaignId } }"
            for="my-modal"
            class="mr-auto btn btn-outline"
          >
            Scoreboard
          </router-link>
          <button
            @click="openChargbeeCheckout"
            class="btn"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
    <!-- <share-modal
      :open="showShareModal"
      :candidate="mainCandidate"
      @close="showShareModal = false"
      :campaign-id="this.campaignId"
    /> -->
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import { About, Scales, GameAnalysis, TeamAbilityComparisonRadar, AboutTheNorm } from '@/content/reportContent.json'

import ComparisonPage from '../components/ComparisonPage.vue'
import AboutPage from '../components/AboutPage.vue'
import GameAnalysisPage from '../components/GameAnalysisPage.vue'
import ScalesPage from '../components/ScalesPage.vue'

import HorizontalReportMenu from '@/components/reports/HorizontalReportMenu.vue'
import AboutTheNormPage from '@/modules/AppReport/components/AboutTheNormPage.vue'
// import ShareModal from '../components/ShareModal.vue'

// import download from 'downloadjs'

export default {
  components: {
    ComparisonPage,
    AboutPage,
    GameAnalysisPage,
    ScalesPage,
    HorizontalReportMenu,
    AboutTheNormPage
    // ShareModal
  },

  props: {
    campaignId: {
      type: String,
      required: false,
      default: null
    },
    pdf: {
      type: Boolean,
      required: false,
      default: false
    },
    organisationId: {
      type: String,
      required: false,
      default: null
    },
    otp: {
      type: String,
      required: false,
      default: null
    },
    anonymized: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data () {
    return {
      page: 4,
      expired: false,
      showCheckoutInfo: false,
      hostedPage: null,
      loadedData: false,
      isDownloading: false,
      showShareModal: false,
      allIssues: [],
      maxPages: 5,
      closeReportModals: false
    }
  },

  async created () {
  },
  watch: {
    page () {
      if (this.page < 0) this.page = 0
      if (this.page > this.maxPages - 1) this.page = this.maxPages
    }
  },
  computed: {
    ...mapGetters({
      mainMeasures: 'TEAMREPORT/getMainMeasures',
      isLoggedAdminIn: 'ADMIN/isLoggedAdminIn',
      issues: 'SCOREBOARD/getCandidatesTestIssues',
      lowScoreComments: 'SCOREBOARD/getLowScoreComments'
    }),
    noResults () {
      return this.$store.state.TEAMREPORT.candidates.length === 0
    },
    reportResultPages () {
      return [
        TeamAbilityComparisonRadar
      ]
    },
    reportInfoPages () {
      return [
        About,
        AboutTheNorm,
        Scales,
        GameAnalysis
      ]
    },
    data () {
      return {
        labels: this.mainMeasures.map(el => el.label),
        datasets: [
          {
            label: 'The Candidate',
            data: this.mainMeasures.map(el => el.stanine),
            barThickness: 56
          }
        ]
      }
    }
  },
  methods: {
    async initReport () {
      // if otp we dont need the other candidates data
      if (!this.otp) {
        await this.$store.dispatch('TEAMREPORT/getCandidates', this.campaignId)
      }

      if (!this.otp) {
        await this.$store.dispatch('SCOREBOARD/getCandidatesTestIssues', this.campaignId)
        // this.allIssues = this.issues.filter(el => el.user_id === this.userId)
        // if (this.allIssues && this.allIssues.length > 0) {
        //   await this.$store.dispatch('SCOREBOARD/getLowScoreComments', { test_session_id: this.allIssues[0].test_session_id, user_id: this.allIssues[0].user_id })
        // }
      }
    },
    openChargbeeCheckout () {
      this.showCheckoutInfo = false

      const hostedPage = new Promise((resolve, reject) => resolve(this.hostedPage))
      let success = false

      this.$chargebee.openCheckout({
        hostedPage: () => {
          return hostedPage
        },
        loaded: function () {
        },
        close: async () => {
          if (!success) { // If the user fails to complete the checkout. Route them back to the scoreboard.
            this.showCheckoutInfo = true
          }
        },
        success: async (hostedPageId) => { // success callback is called before close. We use that to check if checkout was successfull in the close callback.
          success = true

          // End subscription trial
          await this.$api.endChargebeeTrial()
          await this.initReport()
        },
        step: function (value) {
          // value -> which step in checkout
        }
      })
    },
    async downloadReport (anonymized) {
      // this.isDownloading = true
      // const { data, headers } = await this.$api.getPdfReport(this.userId, this.campaignId, anonymized)
      // const contentType = headers['content-type']
      // download(data, `Full cognitive report ${anonymized ? `Candidate ${this.mainCandidate.alt_id}` : this.mainCandidateName}`, contentType)

      // this.isDownloading = false
    },

    async downloadRawDataReport () {
      // this.isDownloading = true
      // const { data, headers } = await this.$api.getRawDataReport(this.userId, this.campaignId)

      // const contentType = headers['content-type']
      // download(data, '', contentType)

      // this.isDownloading = false
    }
  },
  destroyed () {
    this.$store.dispatch('TEAMREPORT/empty')
  }
}
</script>

<style lang="scss">@import '../report.scss';
  // Make the scrollbar always visible when page is overflowing
  ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>
